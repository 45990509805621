import React, { PureComponent } from 'react';
import { Checkbox, Button, Form } from 'antd';
import { Statistics, ViewTable, Layout } from '@/components';
import apis from '@/api/api';

export default class ActivityDetail extends PureComponent<{
  id: string
  activityType: IActivityType | 'GIFT_PRODUCT'
}> {

  state = {
    activityName: '',
    activityTypeDesc: '',
    startDatetime: '',
    endDatetime: '',
    rules: [],
    withCoupon: false,
    expandedRowKeys: [],
    customerCount: 0,
    saleCount: 0,
    giftCount: 0,
    saleAmount: '0.00'
  }
  
  componentDidMount() {
    this.findDetail();
  }

  findDetail = () => {
    apis.activity_fd.post({
      activityId: this.props.id,
    })
    .then((data: any) => {
      this.setState({
        activityName: data.activityName,
        activityTypeDesc: data.activityTypeDesc,
        startDatetime: data.startDatetime,
        endDatetime: data.endDatetime,
        rules: data.rules,
        withCoupon: data.withCoupon,
        customerCount: data.customerCount,
        saleCount: data.saleCount,
        giftCount: data.giftCount,
        saleAmount: data.saleAmount
      })
    })
  }

  findRuleDtlFAC = () => {

  }

  getColumns = (): any[] => {
    if (this.props.activityType === 'DISCOUNT') return this.getDiscountColumns();
    if (this.props.activityType === 'SPECIAL') return this.getSpecialColumns();
    if (this.props.activityType === 'GIFT') return this.getGiftColumns();
    if (this.props.activityType === 'GIFT_PRODUCT') return this.getGiftProductColumns();
    return [];
  }

  private getDiscountColumns = () => {
    return [
      {
        title: '折扣',
        dataIndex: 'discount',
        width: 'auto',
        align: 'left',
        titleAlign: 'left',
        render: (value) => value || '--'
      },
    ];
  }

  private getSpecialColumns = () => {
    return [
      {
        title: '特价',
        dataIndex: 'dtls',
        width: 'auto',
        titleAlign: 'left',
        render: (dtls) => {
          return dtls.map(item => {
            return <div style={{minWidth: 300}}>
              <span style={{color: 'red', display: 'inline-block', width: 60}}>{item.price}</span>
              <span style={{textDecoration: 'line-through', display: 'inline-block', width: 60, color: '#333'}}>{item.originalPrice}</span>
              {item.skuName}
            </div>
          })
        }
      },
    ];
  }

  private getGiftColumns = () => {
    return [
      {
        title: '满赠规则',
        dataIndex: 'giftConditionDesc',
        align: 'center',
        width: 100,
      },
      {
        title: '赠送方式',
        dataIndex: 'giftMethodDesc',
        width: 100,
        align: 'center',
      },
      {
        title: '赠品规则',
        dataIndex: 'giftRuleDesc',
        width: 100,
        align: 'center',
      },
      {
        title: '计算规则',
        dataIndex: 'giftAlgorithmDesc',
        width: 100,
        align: 'center',
      },
      {
        title: '规则描述',
        width: 500,
        dataIndex: 'dtls',
        render: dtls => {
          return dtls.map((item, index) => {
            return <div key={index}>{item.desc}</div>
          })
        }
      },
    ];
  }

  private getGiftProductColumns = () => {
    return [
      {
        title: '赠送规则',
        dataIndex: 'giftDesc',
        width: 300,
      },
      {
        title: '赠送商品',
        dataIndex: 'giftProductName',
        width: 200,
      },
    ];
  }

  render() {
    const giftColsAppend = this.props.activityType === 'GIFT' ? [{
      label: '赠品数量',
      value: this.state.giftCount
    }] : [];
    return (<Layout.Page>
      <Layout flexColumn overHidden>
        <div style={{background: '#fff', padding: '16px 16px'}}>
          <Layout.Title icon="icon-info">活动信息</Layout.Title>
          <Form labelCol={{span: 4}} className="layout-form" layout="inline">
            <Form.Item label="活动名称" style={{width: 360}}>
              {this.state.activityName}
            </Form.Item>
            <Form.Item label="开始时间" style={{width: 360}}>
              {this.state.startDatetime}
            </Form.Item>
            <Form.Item label="结束时间" style={{width: 360}}>
              {this.state.endDatetime}
            </Form.Item>
          </Form>
          <Statistics options={[
            {
              label: '参与客户数',
              value: this.state.customerCount
            },
            {
              label: '订单数',
              value: this.state.saleCount
            },
            {
              label: '订单金额（元）',
              value: this.state.saleAmount
            },
            ...giftColsAppend,
          ]}/>
        </div>

        <div className="flex-columns" style={{marginTop: 10, padding: '16px', background: '#fff', flex: 1, overflow: 'hidden'}}>
          <Layout.Title icon="icon-cuxiao">活动规则</Layout.Title>
          <ViewTable
            flex1
            fetchOption={{
              api: apis.activity_rule_dtl_fac,
              data: {
                activityId: this.props.id
              }
            }}
            columns={[
              {
                title: '商品编号',
                dataIndex: 'productId',
                width: 200,
                align: 'center',
              },
              {
                title: '商品名称',
                dataIndex: 'productName',
                width: 260,
              },
              {
                title: '商品分类',
                dataIndex: 'categoryName',
                width: 200,
              },
              ...this.getColumns()
            ]}
          />
          <div style={{marginTop: 16}}>
            <span style={{marginRight: 16}}>不能同时使用</span>
            <Checkbox checked={!this.state.withCoupon} disabled>优惠券</Checkbox>
          </div>
        </div>
      </Layout>
    </Layout.Page>);
  }
}
